import Chat from 'chat';
import { useState } from 'react';

import { Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';

import Header from 'components/Header';

interface Props {
  anchorEl?: HTMLElement | null;
  buttonHeight: string;
}

export default function PopOver({ anchorEl, buttonHeight }: Props) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Popper
      disablePortal={true}
      id="chainlit-copilot-popover"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="top-end"
      modifiers={[
        {
        name: 'computeStyles',
        options: {
          adaptive: true, // true by default
        },
      }]
      }
      sx={{
        display: 'flex',
        position: expanded ? 'fixed!important' : 'initial',
        flexDirection: 'column',
        inset: expanded 
         ? 'auto auto 0px 0px !important'
         : 'auto 0 14px -24px',
        height: expanded
          ? `100%`
          : `min(730px, calc(100vh - ${buttonHeight} - 96px))`,
        width: expanded ? '100%' : 'min(400px, 80vw)',
        overflow: 'hidden',
        borderRadius: expanded ? '0px' : '12px',
        top: "auto!important",
        background: (theme) => theme.palette.background.default,
        transform: expanded ? 'translate(0,0)!important': 'inherit',
        boxShadow:
          '0 6px 6px 0 rgba(167, 104, 104, 0.02),0 8px 24px 0 rgba(0,0,0,.12)!important',
        zIndex: 1200
      }}
    >
      <Fade in={!!anchorEl}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%'
          }}
        >
          <Header expanded={expanded} setExpanded={setExpanded} />
          <Chat />
        </Box>
      </Fade>
    </Popper>
  );
}
