export const Cognito = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 256 299"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M208.752 58.061l25.771-6.636.192.283.651 155.607-.843.846-5.31.227-20.159-3.138-.302-.794V58.061M59.705 218.971l.095.007 68.027 19.767.173.133.296.236-.096 59.232-.2.252-68.295-33.178v-46.449"
        fill="#7A3E65"
      />
      <path
        d="M208.752 204.456l-80.64 19.312-40.488-9.773-27.919 4.976L128 238.878l105.405-28.537 1.118-2.18-25.771-3.705"
        fill="#CFB2C1"
      />
      <path
        d="M196.295 79.626l-.657-.749-66.904-19.44-.734.283-.672-.343L22.052 89.734l-.575.703.845.463 24.075 3.53.851-.289 80.64-19.311 40.488 9.773 27.919-4.977"
        fill="#512843"
      />
      <path
        d="M47.248 240.537l-25.771 6.221-.045-.149-1.015-155.026 1.06-1.146 25.771 3.704v146.396"
        fill="#C17B9E"
      />
      <path
        d="M82.04 180.403l45.96 5.391.345-.515.187-71.887-.532-.589-45.96 5.392v62.208"
        fill="#7A3E65"
      />
      <path
        d="M173.96 180.403L128 185.794v-72.991l45.96 5.392v62.208M196.295 79.626L128 59.72V0l68.295 33.177v46.449"
        fill="#C17B9E"
      />
      <path
        d="M128 0L0 61.793v175.011l21.477 9.954V90.437L128 59.72V0"
        fill="#7A3E65"
      />
      <path
        d="M234.523 51.425v156.736L128 238.878v59.72l128-61.794V61.793l-21.477-10.368"
        fill="#C17B9E"
      />
    </svg>
  );
};
