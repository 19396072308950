import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ImageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{
        fill: 'currentColor'
      }}
      viewBox="0 0 24 24"
    >
      <path d="M12.588 11.646a1.5 1.5 0 1 1-2.97.418 1.5 1.5 0 0 1 2.97-.418Z"></path>
      <path
        fillRule="evenodd"
        d="M11.297 1.03c-1.732-.232-3.403.918-3.66 2.674L7.04 7.759l-3.33.468a3.148 3.148 0 0 0-2.68 3.555l1.197 8.508a3.148 3.148 0 0 0 3.555 2.68l8.508-1.197a3.148 3.148 0 0 0 2.68-3.555l-.2-1.42 1.285.172c1.732.233 3.403-.918 3.661-2.674l1.251-8.508c.26-1.766-1.03-3.329-2.769-3.562L11.297 1.03Zm5.183 13.71 1.842.248c.727.097 1.328-.389 1.415-.983l1.252-8.508c.085-.582-.338-1.192-1.057-1.289l-8.901-1.196c-.727-.097-1.329.389-1.416.983l-.51 3.474 3.113-.438a3.148 3.148 0 0 1 3.555 2.68l.707 5.03Zm-2.687-4.752a1.148 1.148 0 0 0-1.297-.977l-8.508 1.196a1.148 1.148 0 0 0-.977 1.297l.619 4.4 1.068-1.063a3.148 3.148 0 0 1 4.115-.283l6.08 4.582c.09-.195.128-.416.096-.644l-1.196-8.508Zm-1.109 9.991L7.61 16.155a1.148 1.148 0 0 0-1.5.103L3.978 18.38l.23 1.632a1.148 1.148 0 0 0 1.296.977l7.18-1.01Z"
        clipRule="evenodd"
      ></path>
    </SvgIcon>
  );
};

export default ImageIcon;
